import request from '@/utils/request'
const controller = '/UnitTag'

const getUnitTagList = unitId =>
  request({
    url: `${controller}/GetUnitTagList`,
    method: 'get',
    params: { unitId }
  });
export {
  getUnitTagList
}