<template>
  <div class="g-section job-container">
    <div class="g-wrap job-top">
      <div class="flex">
        <el-input class="keyword-search" placeholder="请输入关键字查询" suffix-icon="el-icon-search" v-model="keyword" @input="loadData(true)" />
        <div class="tag-search">
          <span style="margin-right:10px;">分类:</span>
          <el-select v-model="TagId" clearable placeholder="请选择" @change="loadData(true)">
            <el-option v-for="item in unitTagList" :key="item.Value" :label="item.Text" :value="item.Value" />
          </el-select>
        </div>
      </div>
      <el-button type="primary" icon="el-icon-circle-plus-outline" @click="goCreate">添加客户</el-button>
    </div>
    <div class="g-wrap job-bottom">
      <div v-for="(item) in list" class="job-item" :key="item.Id">
        <div class="item-top">
          <div class="item-left">
            <div class="mb-10 fs-14 color-base flex">
              <el-image :src="item.EnterpriseLogo" class="logo">
                <div slot="error">
                  <div class="image-slot">
                    <span v-if="item.EnterpriseLogo">加载失败</span>
                    <i v-else class="el-icon-picture-outline"></i>
                  </div>
                </div>
              </el-image>
              <span class="mb-10 job-name">{{item.EnterpriseName}}</span>
            </div>
            <div class="fs-14">
              <el-tag class="tag-name" size="mini" v-for="({TagName,Id}) in item.CooperationTagList" :key="Id">{{TagName}}</el-tag>
            </div>
          </div>
        </div>
        <div class="item-bottom">
          <el-button type="text" icon="el-icon-s-promotion" size="small" class="color-primary" @click="goJob(item.EnterpriseId)">招聘岗位（{{item.JobCount}}）</el-button>
          <el-button type="text" icon="el-icon-search" size="small" class="color-primary" @click="goDetail(item.Id)">查看</el-button>
          <el-button type="text" icon="el-icon-edit" size="small" class="color-primary" @click="goEdit(item.Id)">编辑</el-button>
          <el-button type="text" icon="el-icon-delete" size="small" class="color-primary" @click="deleteEnterprise(item.Id)">删除</el-button>
        </div>
      </div>
      <div class="loading">
        <div v-if="loading">加载中...</div>
        <div v-else-if="noMore">—— 没有更多了 ——</div>
        <el-button v-else @click="loadData(false)" type="text">加载更多</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getPageList, deleteEnterprise } from "@/api/cooperation";
import { StaffTypeEnum } from '@/utils/constant';
import { getUnitTagList } from '@/api/unittag';
export default {
  data() {
    return {
      UnitId: undefined,
      UnitType: StaffTypeEnum.Enterprise,
      TagId: undefined,
      list: [],
      keyword: "",
      loading: true,
      pageIndex: 0,
      pageSize: 5,
      totalCount: 0,
      unitTagList: []
    };
  },
  computed: {
    ...mapGetters(['employee']),
    noMore() {
      return this.loading == false && this.list.length == this.totalCount;
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const { Employer: { Id } = {} } = this.employee.find(({ StaffType }) => StaffType === StaffTypeEnum.Enterprise) || {};
      if (Id) {
        this.UnitId = Id;
        this.loadData(true);
        getUnitTagList(Id).then(({ Result }) => {
          this.unitTagList = Result.map(({ Text, Value }) => ({
            Text,
            Value
          }));
        })
      }
    },
    loadData(isInit) {
      this.loading = true;
      this.pageIndex = isInit ? 1 : (this.pageIndex + 1);
      var queryData = {
        KeyWord: this.keyword,
        PageSize: this.pageSize,
        PageIndex: this.pageIndex,
        UnitId: this.UnitId,
        TagId: this.TagId
      };
      getPageList(queryData).then(({ Result: { Data, TotalCount } }) => {
        this.list = isInit ? Data : this.list.concat(Data);
        this.totalCount = TotalCount;
        this.loading = false;
      });
    },
    deleteEnterprise(id, index) {
      this.$confirm("删除后无法恢复，确认删除？", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteEnterprise(id).then((_) => {
          this.list.splice(index, 1);
          if (this.totalCount > 0) {
            this.totalCount--;
          }
          this.$message.success("删除成功");
        });
      }).catch((_) => { });
    },
    goCreate() {
      this.$router.push('/enterprise/cooperation/create')
    },
    goDetail(id) {
      this.$router.push(`/enterprise/cooperation/detail?id=${id}`);
    },
    goEdit(id) {
      this.$router.push(`/enterprise/cooperation/edit?id=${id}`);
    },
    goJob(enterpriseId) {
      this.$router.push(`/enterprise/job?e=${enterpriseId}`);
      // this.$router.push({ name: 'ListJobEnterprise', params: { enterpriseId }})
    }
  },
};
</script>
<style lang="scss" scoped>
.job-container {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  background: #f5f5f5;
}

.job-top {
  justify-content: space-between;
  margin-top: 20px;
  border-radius: 8px 8px 0px 0px;
}
.g-wrap {
  background: #fff;
}
.g-wrap.job-top {
  padding: 24px 24px 16px;
  border-bottom: 1px solid #eeeeee;
  // flex-direction: column;
}
.g-wrap.job-bottom {
  padding: 16px 24px;
  margin-bottom: 20px;
}
.keyword-search{
  width: 200px;
}
.tag-search {
  margin-left: 20px;
}

.btn-search {
  margin-left: 10px;
}

.job-bottom {
  display: flex;
  flex-direction: column;
  // margin-top: 20px;
  border-radius: 0px 0px 8px 8px;
}

.job-item {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
  border: 1px solid #e2e4e8;
  border-radius: 6px;

  &:hover {
    box-shadow: 0px 2px 6px 0px #e7e7e7;
  }

  .item-top {
    padding: 10px;
    display: flex;
    justify-content: space-between;

    .item-left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      .job-name {
        font-size: 18px;
        font-weight: 600;
        color: #333333;
        margin-left: 20px;
      }
    }

    .item-right {
      display: flex;
      align-items: center;

      .right-btn {
        cursor: pointer;
        margin-left: 20px;
        display: flex;
        flex-direction: column;
      }
    }
  }

  .item-bottom {
    display: flex;
    border-top: 1px solid #e7e8ea;
    background: #eeeeee;
    height: 32px;
    align-items: center;
    justify-content: flex-end;
    padding: 8px 10px;
    border-radius: 0 0 6px 6px;
  }

  .el-button {
    padding: 0px;
  }
}

.loading {
  text-align: center;
}
.image-slot {
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f7fa;
}
.logo {
  width: 60px;
  height: 60px;
}
.tag-name {
  margin-right: 10px;
}
</style>
