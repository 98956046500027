import request from '@/utils/request'
const controller = '/Cooperation'

const getPageList = params =>
  request({
    url: `${controller}/GetPageList`,
    method: 'get',
    params
  });

const deleteEnterprise = id =>
  request({
    url: `${controller}/Delete`,
    method: 'post',
    params: { id }
  });

const getInfo = id =>
  request({
    url: `${controller}/GetInfo`,
    method: 'get',
    params: { id }
  });

const insert = data =>
  request({
    url: `${controller}/Insert`,
    method: "post",
    data
  });

const edit = data =>
  request({
    url: `${controller}/Update`,
    method: "post",
    data
  });

const getCustomerList = unitId =>
  request({
    url: `${controller}/GetCustomerList`,
    method: "get",
    params: { unitId }
  });

export {
  getPageList,
  deleteEnterprise,
  getInfo,
  insert,
  edit,
  getCustomerList
}